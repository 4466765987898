<template>
  <div id="educators" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="educators" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          educators.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Avatar" v-slot="props">
          <figure class="image is-48x48">
            <img
              class="is-rounded"
              :src="props.row.image_url"
              alt="avatar"
              v-if="props.row.image_url"
            />
            <img
              class="is-rounded"
              src="@/assets/images/default_avatar.png"
              alt="avatar"
              v-else
            />
          </figure>
        </b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Phone" v-slot="props">{{
          props.row.phone
        }}</b-table-column>

        <!-- <b-table-column label="G Suite" v-slot="props">
          <fa-icon
            icon="user-check"
            size="lg"
            class="is-small has-text-success"
            v-if="props.row.ownerId"
          />
          <fa-icon
            icon="user-times"
            size="lg"
            class="is-small has-text-danger"
            v-else
          />
        </b-table-column> -->

        <b-table-column label="Auth" v-slot="props">
          <fa-icon
            icon="user-check"
            size="lg"
            class="is-small has-text-success"
            v-if="props.row.user.firebaseUserId.length > 10"
          />
          <fa-icon
            icon="user-times"
            size="lg"
            class="is-small has-text-danger"
            v-else
          />
        </b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            position="is-left"
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-educator"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
const randomstring = require('randomstring')
import EDUCATORS from '../../../graphql/educator/Educators.gql'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'educators',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Educators',
      isActive: false,
      educators: [],
      id: null,
      error: null,
    }
  },
  methods: {
    initializeInfo(educator) {
      this.$router.push(`/school/${this.schoolId}/educator_info/${educator.id}`)
    },
    initializeUpdate(educator) {
      this.$router.push(`/school/${this.schoolId}/educator/${educator.id}`)
    },
    initializeRemove(educator) {
      this.id = educator.id
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteEducatorQuery($id: Int!) {
              deleteEducator(input: { id: $id }) {
                educator {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteEducator.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.educators.refetch()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/educator/new`,
      },
    ])

    this.$apollo.addSmartQuery('educators', {
      query: EDUCATORS,
      error(error) {
        this.error = error.message
      },
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.educators.refetch()
  },
}
</script>
